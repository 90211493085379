<template>
  <div class="card mt-3">
    <div class="card-header pb-0">
      <h5 class="card-title">{{ $t('hosting.order.hostingInfo') }}</h5>
      <hr/>
    </div>
    <div class="card-body pt-3">
      <lev-input class="mb-3" :label="$t('hosting.order.domain')" v-model="domain"/>
      <lev-input class="mb-3" :label="$t('hosting.order.email')" v-model="email"/>
      <lev-input class="mb-3" :label="$t('hosting.order.username')" v-model="username"/>
    </div>
  </div>
</template>

<script>
import LevInput from "@/components/children/lev_components/LevInput.vue";

export default {
  name: "HostingInfo",
  components: {LevInput},
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    value: {
      handler: function (value) {
        this.username = value.username?.toLowerCase()
        this.domain = value.domain
        this.email = value.email
      },
      immediate: true
    },
    username: {
      handler: function () {
        this.updateValue()
      }
    },
    domain: {
      handler: function () {
        this.updateValue()
      }
    },
    email: {
      handler: function () {
        this.updateValue()
      }
    }
  },
  data () {
    return {
      username: '',
      domain: '',
      email: ''
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', {
        username: this.username.toLowerCase(),
        domain: this.domain,
        email: this.email
      })
    }
  }
}
</script>
