<template>
  <div class="container">
    <div class="card mt-3">
      <div class="card-title m-3">
        <div class="d-flex justify-content-between align-items-center">
          <a
            href="#"
            @click.prevent="backToPreviousStep()"
          >
            <i class="fa fa-arrow-circle-left">
              <span>Back</span>
            </i>
          </a>
          <span class="float-right"> {{ $t('orderDetail.cardHeader.titleStatus') }}:
            <span v-if="orderObject['status'] === 'Unpaid'"> <span class="badge badge-danger"><strong>{{ $t('orderDetail.cardHeader.titleStatusUnpaid') }}</strong></span></span>
            <span v-else-if="orderObject['status'] === 'Pending'"> <span class="badge badge-info"><strong>{{ $t('orderDetail.cardHeader.titleStatusPending') }}</strong></span></span>
            <span v-else-if="orderObject['status'] === 'Partially'"> <span class="badge badge-info"><strong>{{ $t('orderDetail.cardHeader.titleStatusPartially') }}</strong></span></span>
            <span v-else-if="orderObject['status'] === 'Installing'"> <span class="badge badge-primary"><strong>{{ $t('orderDetail.cardHeader.titleStatusInstalling') }}</strong></span></span>
            <span v-else-if="orderObject['status'] === 'Renewed'"> <span class="badge badge-success"><strong>{{ $t('orderDetail.cardHeader.titleStatusRenewed') }}</strong></span></span>
            <span v-else-if="orderObject['status'] === 'Credited'"> <span class="badge badge-success"><strong>{{ $t('orderDetail.cardHeader.titleStatusCredited') }}</strong></span></span>
            <span v-else-if="orderObject['status'] === 'Provisioned'"> <span class="badge badge-success"><strong>{{ $t('orderDetail.cardHeader.titleStatusProvisioned') }}</strong></span></span>
            <span v-else-if="orderObject['status'] === 'Cancelled'"> <span class="badge badge-secondary"><strong>{{ $t('orderDetail.cardHeader.titleStatusCancelled') }}</strong></span></span>
            <span v-else-if="orderObject['status'] === 'Finished'"> <span class="badge badge-success"><strong>{{ $t('orderDetail.cardHeader.titleStatusFinished') }}</strong></span></span>
            <span v-else> <span class="badge badge-dark"><strong>{{ $t('orderDetail.cardHeader.titleStatusFraud') }}</strong></span></span>
          </span>
        </div>
        <hr>
      </div>
      <div class="card-body pt-0">
        <div class="max-width-1200 m-auto">
          <div class="row mt-2 mb-2">
            <div class="col-lg-5 col-sm-12">
              {{ $t('orderDetail.label.invoiceID') }}:
              <h3><strong class="text-danger">{{ metaData.siteTicker }}{{ orderObject.id }}</strong></h3>
              <span class="fa fa-calendar" />
              {{ convertISODate(orderObject['created_at']) }}
            </div>
            <div class="col-lg-4 col-sm-12">
              {{ $t('orderDetail.label.due') }}:
              <h3><strong :class="due.textClass">{{ USDCurrencyFormat(due.amount) }}</strong></h3>
              <span class="fa fa-cube" />
              <span
                v-if="metaData.defaultCurrency !== 'USD'"
                class="ms-1"
              >{{ currencyFormat(due.amount * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }} </span>
            </div>
            <div class="col-lg-3 col-sm-12">
              <div v-if="orderObject['status'] === 'Unpaid'">
                <lev-button
                  name="Next button"
                  class="d-block mb-1 width-full"
                  variant="success"
                  :loading="isButtonLoading"
                  @click="nextToPaymentDetail()"
                >
                  <span v-if="isButtonLoading">{{ $t('orderDetail.button.initializing') }}</span>
                  <span v-else>{{ $t('orderDetail.button.next') }} <i class="fa fa-arrow-circle-right" /></span>
                </lev-button>
                <button
                  class="btn btn-secondary d-block width-full"
                  :disabled="isButtonLoading"
                  @click="cancel"
                >
                  <span class="fa fa-times-circle-o" />
                  {{ $t('orderDetail.button.cancel') }}
                </button>
              </div>
              <div v-else-if="orderObject['status'] !== 'Unpaid'">
                {{ $t('orderDetail.label.paymentDetail') }}
                <div v-if="selectedPaymentDetail['payment_name'] === 'Balance'">
                  <span class="text-success payment-detail-small"><i class="fas fa-wallet me-2 text-dark" />{{ $t('orderDetail.label.paymentDetailAsBalance') }}</span>
                </div>
                <div v-else-if="selectedPaymentDetail['payment_name'] === 'LPOINT'">
                  <span class="text-success payment-detail-small"><i class="fas fa-wallet me-2 text-dark" />{{ $t('orderDetail.label.paymentDetailAsLPoint') }}</span>
                </div>
                <div v-else>
                  <div class="payment-detail-small">
                    <span class="text-success"><i class="fas fa-university me-2 text-dark" />{{ selectedPaymentDetail['payment_name'] }}</span><br>
                    <span class="text-danger"><i class="fas fa-wallet me-2 text-dark" />{{ selectedPaymentDetail['account_no'] }}</span><br>
                    <span class="text-danger"><i class="fas fa-user me-2 text-dark" />{{ selectedPaymentDetail['account_holder'] }}</span><br>
                    <span class="text-warning"><i class="fas fa-pen me-2 text-dark" />{{ moneyTransferContent }}</span><br>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div v-if="this.$route.query['step'] === undefined || this.$route.query['step'] === '1'">
            <div class="row mb-4">
              <div class="col-sm-6">
                <h6 class="mb-3">
                  {{ $t('orderDetail.label.from') }}:
                </h6>
                <div>
                  <strong>{{ metaData.siteName }}</strong>
                </div>
                <div><span class="fa fa-globe" /> <span v-html="getWebsiteContactDisplay" /></div>
                <div><span class="fa fa-envelope" /><a :href="'mailto:' + metaData.contactEmail"> {{ metaData.contactEmail }}</a></div>
                <div><span class="fa fa-phone" /><a :href="'tel:' + metaData.contactPhone"> {{ metaData.contactPhone }}</a></div>
              </div>

              <div class="col-sm-6">
                <h6 class="mb-3">
                  {{ $t('orderDetail.label.to') }}:
                </h6>
                <div>
                  <strong>{{ currentUser['fullName'] }}</strong>
                </div>
                <div><span class="fa fa-user" /> {{ currentUser['username'] }}</div>
                <div><span class="fa fa-facebook" /> <a :href="currentUser['facebook']">{{ currentUser['facebook'] }}</a></div>
                <div><span class="fa fa-envelope" /> {{ currentUser['email'] }}</div>
              </div>
            </div>
            <div
              v-if="orderObject['type'] === 'topup'"
              class="table-responsive"
            >
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="center">
                      #
                    </th>
                    <th>{{ $t('orderDetail.table.thItem') }}</th>
                    <th>{{ $t('orderDetail.table.thDescription') }}</th>
                    <th>{{ $t('orderDetail.table.thUnitCost') }}</th>
                    <th>{{ $t('orderDetail.table.thTotal') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="center">
                      1
                    </td>
                    <td class="left font-weight-bold">
                      Topup
                    </td>
                    <td class="left text-wrap">
                      Deposit ${{ orderObject['total_amount'] }} to balance
                    </td>
                    <td class="right">
                      ${{ orderObject['total_amount'] }}
                    </td>
                    <td class="right">
                      ${{ orderObject['total_amount'] }}
                      <span v-if="metaData.defaultCurrency !== 'USD'">({{ currencyFormat(orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else-if="orderObject['type'] === 'new'">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">
                        #
                      </th>
                      <th>{{ $t('orderDetail.table.thItem') }}</th>
                      <th>{{ $t('orderDetail.table.thDescription') }}</th>
                      <th>{{ $t('orderDetail.table.thUnitCost') }}</th>
                      <th>{{ $t('orderDetail.table.thQty') }}</th>
                      <th>{{ $t('orderDetail.table.thTotal') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(d, index) in orderObject['items']"
                      :key="index"
                    >
                      <td class="center">
                        {{ index + 1 }}
                      </td>
                      <td class="left font-weight-bold">
                        <span v-if="isSetupFee(d['service_other_info'])">
                          {{ d['service_type_name'] }} {{ d['service_plan_name'] }}
                        </span>
                        <span v-else>
                          {{ d['service_type_name'] }} {{ d['service_plan_name'] }} x {{ d['period'] }} {{ parseInt(d['period']) > 1 ? $t('orderDetail.label.periods') : $t('orderDetail.label.period') }}
                        </span>
                      </td>
                      <td class="left text-wrap">
                        <span v-if="isSetupFee(d['service_other_info'])">
                          {{ $t('orderDetail.label.setupFee') }}
                        </span>
                        <span v-else>
                          Location: {{ d['country'] }} - {{ d['state'] }}
                          <span class="small">{{ d['remark'] }}</span>
                        </span>
                      </td>
                      <td class="right">
                        <span v-if="isSetupFee(d['service_other_info'])">
                          {{ USDCurrencyFormat(d['ppu']) }}
                        </span>
                        <span v-else>
                          {{ USDCurrencyFormat(d['ppu'] * d['period']) }}
                        </span>
                      </td>
                      <td class="center">
                        {{ d['qty'] }}
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu'] * d['qty'] * d['period']) }}
                        <span v-if="metaData.defaultCurrency !== 'USD'"> ({{ currencyFormat(d['qty'] * d['ppu'] * d['period'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else-if="orderObject['type'] === 'testing'">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">
                        #
                      </th>
                      <th>{{ $t('orderDetail.table.thItem') }}</th>
                      <th>{{ $t('orderDetail.table.thDescription') }}</th>
                      <th>{{ $t('orderDetail.table.thUnitCost') }}</th>
                      <th>{{ $t('orderDetail.table.thQty') }}</th>
                      <th>{{ $t('orderDetail.table.thTotal') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(d, index) in orderObject['items']"
                      :key="index"
                    >
                      <td class="center">
                        {{ index + 1 }}
                      </td>
                      <td class="left">
                        {{ $t('orderDetail.label.trial_plan') }} <strong>{{ d['service_type_name'] }} {{ d['service_plan_name'] }}</strong>
                      </td>
                      <td class="left text-wrap">
                        <span v-if="isSetupFee(d['service_other_info'])">
                          {{ $t('orderDetail.label.setupFee') }}
                        </span>
                        <span v-else>
                          {{ d['country'] }} - {{ d['state'] }}
                          <span class="small">{{ d['remark'] }}</span>
                        </span>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu'] * d['period']) }}
                      </td>
                      <td class="center">
                        {{ d['qty'] }}
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu'] * d['qty'] * d['period']) }}
                        <span v-if="metaData.defaultCurrency !== 'USD'"> ({{ currencyFormat(d['qty'] * d['ppu'] * d['period'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else-if="orderObject['type'] === 'renew'">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">
                        #
                      </th>
                      <th>{{ $t('orderDetail.table.thItem') }}</th>
                      <th>{{ $t('orderDetail.table.thDescription') }}</th>
                      <th>{{ $t('orderDetail.table.thUnitCost') }}</th>
                      <th>{{ $t('orderDetail.table.thTotal') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(d, index) in orderObject['items']"
                      :key="index"
                    >
                      <td class="center">
                        {{ index + 1 }}
                      </td>
                      <td class="left font-weight-bold">
                        {{ $t('orderDetail.table.tdRenewVPS', [d['service_type_name'], d['service_plan_name']]) }} x {{ d['period'] }} {{ parseInt(d['period']) > 1 ? $t('orderDetail.label.periods') : $t('orderDetail.label.period') }}
                      </td>
                      <td class="left text-wrap">
                        <span
                          v-html="$t('orderDetail.table.tdRenewVPSDescription',
                                     [d['service_type_name'], d['service_main_info'], convertISODate(JSON.parse(d['service_other_info'])['end_date']), convertISODatePlusMonths(JSON.parse(d['service_other_info'])['end_date'], d['period'])])"
                        />
                        <div
                          v-if="JSON.parse(d['service_other_info'])['addon'] !== undefined && JSON.parse(d['service_other_info'])['addon'] !== '' && JSON.parse(d['service_other_info'])['addon'] !== null"
                          class="small ps-3 text-info"
                        >
                          <span class="fa fa-puzzle-piece pl-5">
                            <span
                              class="small"
                              v-html="JSON.parse(d['service_other_info'])['addon']"
                            />
                          </span>
                        </div>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu'] * d['period']) }}
                        <span
                          v-if="metaData.defaultCurrency !== 'USD'"
                          class="small"
                        >({{ currencyFormat(d['ppu'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu'] * d['period']) }}
                        <span
                          v-if="metaData.defaultCurrency !== 'USD'"
                          class="small"
                        >({{ currencyFormat(d['ppu'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else-if="orderObject['type'] === 'promotion'">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">
                        #
                      </th>
                      <th>{{ $t('orderDetail.table.thItem') }}</th>
                      <th>{{ $t('orderDetail.table.thDescription') }}</th>
                      <th>{{ $t('orderDetail.table.thUnitCost') }}</th>
                      <th>{{ $t('orderDetail.table.thTotal') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="center">
                        1
                      </td>
                      <td class="left font-weight-bold">
                        {{ $t('orderDetail.table.tdRenewVPS', [orderObject['payment_name']]) }}
                      </td>
                      <td class="left text-wrap">
                        {{ orderObject['remark'] }}
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(orderObject['total_amount']) }}
                        <span class="small">
                          ({{ currencyFormat(orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})
                        </span>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(orderObject['total_amount']) }}
                        <span class="small">
                          ({{ currencyFormat(orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else-if="orderObject['type'] === 'other'">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">
                        #
                      </th>
                      <th>{{ $t('orderDetail.table.thItem') }}</th>
                      <th>{{ $t('orderDetail.table.thDescription') }}</th>
                      <th>{{ $t('orderDetail.table.thUnitCost') }}</th>
                      <th>{{ $t('orderDetail.table.thTotal') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="center">
                        1
                      </td>
                      <td class="left font-weight-bold">
                        {{ $t('orderDetail.table.tdOther', [orderObject['payment_name']]) }}
                      </td>
                      <td class="left text-wrap">
                        <span v-html="orderObject['remark']" />
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(orderObject['total_amount']) }}
                        <span class="small">
                          ({{ currencyFormat(orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})
                        </span>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(orderObject['total_amount']) }}
                        <span class="small">
                          ({{ currencyFormat(orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else-if="orderObject['type'] === 'change'">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">
                        #
                      </th>
                      <th>{{ $t('orderDetail.table.thItem') }}</th>
                      <th>{{ $t('orderDetail.table.thDescription') }}</th>
                      <th>{{ $t('orderDetail.table.thUnitCost') }}</th>
                      <th>{{ $t('orderDetail.table.thTotal') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(d, index) in orderObject['items']"
                      :key="index"
                    >
                      <td class="center">
                        {{ index + 1 }}
                      </td>
                      <td class="left font-weight-bold">
                        {{ $t('orderDetail.table.tdChangeVPS', [d['service_plan_name']]) }}
                      </td>
                      <td class="left text-wrap">
                        <span v-html="$t('orderDetail.table.tdChangeVPSDescription', [d['service_main_info'], d['service_other_info']])" />
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu']) }}
                        <span
                          v-if="metaData.defaultCurrency !== 'USD'"
                          class="small"
                        >({{ currencyFormat(d['ppu'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu']) }}
                        <span
                          v-if="metaData.defaultCurrency !== 'USD'"
                          class="small"
                        >({{ currencyFormat(d['ppu'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else-if="orderObject['type'] === 'sla'">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">
                        #
                      </th>
                      <th>{{ $t('orderDetail.table.thItem') }}</th>
                      <th>{{ $t('orderDetail.table.thDescription') }}</th>
                      <th>{{ $t('orderDetail.table.thUnitCost') }}</th>
                      <th>{{ $t('orderDetail.table.thTotal') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(d, index) in orderObject['items']"
                      :key="index"
                    >
                      <td class="center">
                        {{ index + 1 }}
                      </td>
                      <td class="left font-weight-bold">
                        {{ d['service_type_name'] }} {{ d['service_plan_name'] }}
                      </td>
                      <td class="left text-wrap">
                        <span>
                          Add <strong>{{ JSON.parse(d['service_other_info'])['day_sla'] }} days</strong> for IP <strong>{{ d['service_main_info'] }}</strong> from {{ convertISODate(JSON.parse(d['service_other_info'])['end_date']) }}
                        </span>
                        <div
                          v-if="JSON.parse(d['service_other_info'])['addon'] !== undefined && JSON.parse(d['service_other_info'])['addon'] !== '' && JSON.parse(d['service_other_info'])['addon'] !== null"
                          class="small ps-3 text-info"
                        >
                          <span class="fa fa-puzzle-piece pl-5">
                            <span
                              class="small"
                              v-html="JSON.parse(d['service_other_info'])['addon']"
                            />
                          </span>
                        </div>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu'] * d['period']) }}
                        <span
                          v-if="metaData.defaultCurrency !== 'USD'"
                          class="small"
                        >({{ currencyFormat(d['ppu'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu'] * d['period']) }}
                        <span
                          v-if="metaData.defaultCurrency !== 'USD'"
                          class="small"
                        >({{ currencyFormat(d['ppu'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else-if="orderObject['type'] === 'sync'">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="center">
                        #
                      </th>
                      <th>{{ $t('orderDetail.table.thItem') }}</th>
                      <th>{{ $t('orderDetail.table.thDescription') }}</th>
                      <th>{{ $t('orderDetail.table.thUnitCost') }}</th>
                      <th>{{ $t('orderDetail.table.thPeriod') }}</th>
                      <th>{{ $t('orderDetail.table.thSubtotal') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(d, index) in orderObject['items']"
                      :key="index"
                    >
                      <td class="center">
                        {{ index + 1 }}
                      </td>
                      <td class="left font-weight-bold">
                        {{ d['service_type_name'] }} {{ d['service_plan_name'] }}
                      </td>
                      <td class="left text-wrap">
                        <span>
                          Sync IP <strong>{{ d['service_main_info'] }}</strong> from end date {{ convertISODate(JSON.parse(d['service_other_info'])['end_date']) }} to <strong>{{ JSON.parse(d['service_other_info'])['date_sync'] }}</strong>
                        </span>
                        <div
                          v-if="JSON.parse(d['service_other_info'])['addon'] !== undefined && JSON.parse(d['service_other_info'])['addon'] !== '' && JSON.parse(d['service_other_info'])['addon'] !== null"
                          class="small ps-3 text-info"
                        >
                          <span class="fa fa-puzzle-piece pl-5">
                            <span
                              class="small"
                              v-html="JSON.parse(d['service_other_info'])['addon']"
                            />
                          </span>
                        </div>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu']) }}
                        <span
                          v-if="metaData.defaultCurrency !== 'USD'"
                          class="small"
                        >({{ currencyFormat(d['ppu'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                      <td class="center">
                        {{ d['period'] }}
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(d['ppu'] * d['period']) }}
                        <span
                          v-if="metaData.defaultCurrency !== 'USD'"
                          class="small"
                        >({{ currencyFormat(d['ppu'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-8 col-sm-12 ms-auto">
                <table class="table table-clear">
                  <tbody>
                    <tr>
                      <td class="left">
                        <strong>{{ $t('orderDetail.label.subTotal') }}</strong>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(orderObject['total_amount']) }}
                        <span
                          v-if="metaData.defaultCurrency !== 'USD'"
                          class="small"
                        >({{ currencyFormat(orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="left">
                        <strong>VAT (0%)</strong>
                      </td>
                      <td class="right">
                        {{ USDCurrencyFormat(0) }}
                        <span
                          v-if="metaData.defaultCurrency !== 'USD'"
                          class="small"
                        >({{ currencyFormat(0, metaData.defaultCurrency) }})</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="left">
                        <h5 class="text-danger">
                          {{ $t('orderDetail.label.total') }}
                        </h5>
                      </td>
                      <td class="right">
                        <h5 class="text-danger">
                          {{ USDCurrencyFormat(orderObject['total_amount']) }}
                          <span
                            v-if="metaData.defaultCurrency !== 'USD'"
                            class="small"
                          >({{ currencyFormat(orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!--      Only show data for Unpaid order if step = 2. If other status, show nothing. It means there are no other step for this order.-->
          <div v-else-if="this.$route.query['step'] === '2' && orderObject['status'] === 'Unpaid'">
            <h5>{{ $t('orderDetail.label.selectPayment') }}</h5>
            <div class="row">
              <div :class="![null, undefined, ''].includes(selectedPaymentDetail['vietqr_tmpl']) ? 'col-4' : 'col-7'">
                <div>
                  <div
                    id="bankSelect"
                    class="btn-group flex-wrap"
                    data-toggle="buttons"
                  >
                    <div
                      v-if="orderObject['type'] !== 'topup' && metaData.isMenuTopupEnable === 1"
                      @click="selectPaymentDetail('Balance')"
                    >
                      <input
                        id="Balances"
                        type="radio"
                        class="btn-check"
                        name="banks"
                      >
                      <label
                        class="payment-button btn btn-outline-primary d-flex align-items-center"
                        for="Balances"
                      >
                        <div class="payment-button-div card d-flex align-items-center justify-content-center">
                          <div
                            class="payment-button-img"
                            style="width: 500px"
                          >
                            <i class="fa fa-shopping-cart fa-3x mt-2 text-primary mt-4" /><br>
                            <span class="small">{{ $t('orderDetail.button.credit') }}</span><br>
                            <span class="badge badge-pill badge-success">{{ USDCurrencyFormat(currentUser['balance']) }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div
                      v-if="orderObject['type'] === 'change' && paymentDetailList.find(x => x['payment_name'] === 'LPOINT') !== undefined"
                      @click="selectPaymentDetail('LPOINT')"
                    >
                      <input
                        id="LPOINT"
                        type="radio"
                        class="btn-check"
                        name="banks"
                      >
                      <label
                        class="payment-button btn btn-outline-primary d-flex align-items-center"
                        for="LPOINT"
                      >
                        <div class="payment-button-div card d-flex align-items-center justify-content-center">
                          <div
                            class="payment-button-img"
                            style="width: 500px"
                          >
                            <i class="fas fa-coins fa-3x mt-2 text-warning mt-4" /><br>
                            <!--                            <img class="payment-button-img" :src="'../../assets/img/payments/' + paymentDetailList.find(x => x['payment_name'] === 'LPOINT')['logo']" :alt="paymentDetailList.find(x => x['payment_name'] === 'LPOINT')['payment_name']"><br/>-->
                            <span class="small">{{ $t('orderDetail.button.lpoint') }}</span><br>
                            <span class="badge badge-pill badge-info">{{ currentUser['lpoint'] }} LPOINT</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div
                      v-for="(paymentDetail, index) in paymentDetailList"
                      :key="index"
                      @click="selectPaymentDetail(paymentDetail['payment_name'], paymentDetail['account_no'])"
                    >
                      <div v-if="paymentDetail['payment_name'] !== 'Balance' && paymentDetail['payment_name'] !== 'LPOINT'">
                        <input
                          :id="'payment_name_' + paymentDetail['payment_name']"
                          type="radio"
                          class="btn-check"
                          name="banks"
                          autocomplete="off"
                        >
                        <label
                          class="payment-button btn btn-outline-primary d-flex align-items-center"
                          :for="'payment_name_' + paymentDetail['payment_name']"
                        >
                          <div class="card payment-button-div d-flex align-items-center justify-content-center">
                            <img
                              class="payment-button-img"
                              :src="'../../assets/img/payments/' + paymentDetail['logo']"
                              :alt="paymentDetail['payment_name']"
                            >
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="![null, undefined, ''].includes(selectedPaymentDetail['vietqr_tmpl']) ? 'col-8 payment-info' : 'col-5 payment-info'">
                <div v-if="!['Balance', 'LPOINT'].includes(selectedPaymentDetail['payment_name'])">
                  <div
                    v-if="selectedPaymentDetail !== ''"
                    class="d-flex"
                  >
                    <div v-if="selectedPaymentDetail['vietqr_tmpl'] !== null && selectedPaymentDetail['vietqr_tmpl'] !== ''">
                      <img
                        class="width-full"
                        alt="VietQR"
                        :src="`https://img.vietqr.io/image/${selectedPaymentDetail['payment_name']}-${selectedPaymentDetail['account_no']}-${selectedPaymentDetail['vietqr_tmpl']}.png?amount=${parseInt(orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']))}&addInfo=${moneyTransferContent}&accountName=${selectedPaymentDetail['account_holder']}`"
                      >
                    </div>
                    <div class="table table-responsive">
                      <tbody>
                        <tr>
                          <td><strong>{{ $t('orderDetail.table.tdAccountBank') }}</strong></td>
                          <td class="text-success h6">
                            {{ selectedPaymentDetail['payment_name'] }}
                          </td>
                        </tr>
                        <tr>
                          <td><strong>{{ $t('orderDetail.table.tdAccountNo') }}</strong></td>
                          <td class="text-danger h6">
                            {{ selectedPaymentDetail['account_no'] }}
                          </td>
                        </tr>
                        <tr>
                          <td><strong>{{ $t('orderDetail.table.tdAccountOwner') }}</strong></td>
                          <td class="text-danger h6">
                            {{ selectedPaymentDetail['account_holder'] }}
                          </td>
                        </tr>
                        <tr>
                          <td><strong>{{ $t('orderDetail.table.tdAccountBranch') }}</strong></td>
                          <td class="text-success h6">
                            {{ selectedPaymentDetail['branch'] }}
                          </td>
                        </tr>
                        <tr>
                          <td><strong>{{ $t('orderDetail.table.tdAmount') }}</strong></td>
                          <td class="text-primary h6">
                            {{ USDCurrencyFormat(orderObject['total_amount']) }} <span class="small">({{ currencyFormat(orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</span>
                          </td>
                        </tr>
                        <tr>
                          <td><strong>{{ $t('orderDetail.table.tdNote') }}</strong></td>
                          <td class="text-danger h6">
                            {{ moneyTransferContent }}
                          </td>
                        </tr>
                      </tbody>
                    </div>
                  </div>
                </div>
                <div v-else-if="selectedPaymentDetail['payment_name'] === 'Balance'">
                  <div class="table table-responsive">
                    <tbody>
                      <tr>
                        <td><strong>{{ $t('orderDetail.table.tdCurrentBalance', [metaData.siteName]) }}</strong></td>
                        <td class="pl-5">
                          <strong class="text-success">{{ USDCurrencyFormat(currentUser['balance']) }}</strong> <small class="text-danger font-weight-bold">({{ currencyFormat(currentUser['balance'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</small>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>{{ $t('orderDetail.table.tdAfterBalance') }}</strong></td>
                        <td class="pl-5">
                          <strong class="text-success">{{ USDCurrencyFormat(currentUser['balance'] - orderObject['total_amount']) }}</strong> <small class="text-danger font-weight-bold">({{ currencyFormat((currentUser['balance'] - orderObject['total_amount']) * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</small>
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </div>
                <div v-else-if="selectedPaymentDetail['payment_name'] === 'LPOINT'">
                  <div class="table table-responsive">
                    <tbody>
                      <tr>
                        <td><strong>{{ $t('orderDetail.table.tdCurrentLPoint', [metaData.siteName]) }}</strong></td>
                        <td class="pl-5">
                          <strong class="text-success">{{ currentUser['lpoint'] }} LPOINT</strong>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>{{ $t('orderDetail.table.tdAfterLpoint') }}</strong></td>
                        <td class="pl-5">
                          <strong class="text-success">{{ currentUser['lpoint'] - orderObject['total_amount'] }} LPOINT</strong>
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="this.$route.query['step'] === '3' && orderObject['status'] === 'Pending'">
            <div v-if="!['Balance', 'LPOINT'].includes(selectedPaymentDetail['payment_name'])">
              <div class="row">
                <div class="col">
                  <h5>{{ $t('orderDetail.label.paymentDetailStep3') }}</h5>
                  <div class="table table-responsive">
                    <tbody>
                      <tr>
                        <td><strong>{{ $t('orderDetail.table.tdAccountBank') }}</strong></td>
                        <td class="text-success h6">
                          {{ selectedPaymentDetail['payment_name'] }}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>{{ $t('orderDetail.table.tdAccountNo') }}</strong></td>
                        <td class="text-danger h6">
                          {{ selectedPaymentDetail['account_no'] }}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>{{ $t('orderDetail.table.tdAccountOwner') }}</strong></td>
                        <td class="text-danger h6">
                          {{ selectedPaymentDetail['account_holder'] }}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>{{ $t('orderDetail.table.tdAccountBranch') }}</strong></td>
                        <td class="text-success h6">
                          {{ selectedPaymentDetail['branch'] }}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>{{ $t('orderDetail.table.tdAmount') }}</strong></td>
                        <td class="text-primary h6">
                          {{ USDCurrencyFormat(orderObject['total_amount']) }} ({{ currencyFormat(orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})
                        </td>
                      </tr>
                      <tr>
                        <td><strong>{{ $t('orderDetail.table.tdNote') }}</strong></td>
                        <td class="text-danger h6">
                          {{ moneyTransferContent }}
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </div>
                <!--                <div v-if="selectedPaymentDetail['vietqr_tmpl'] !== null && selectedPaymentDetail['vietqr_tmpl'] !== ''"-->
                <!--                     class="col">-->
                <!--                  <img class="width-full" alt="VietQR" :src="selectedPaymentDetail['vietqr_tmpl'] + '?amount=' + orderObject['total_amount'] * exchangeRateValue(orderObject['created_at']) + '&addInfo=' + metaData.siteTicker + orderObject['id'] + '&accountName=' + selectedPaymentDetail['account_holder'] + ''">-->
                <!--                </div>-->
              </div>
            </div>
            <div v-else-if="selectedPaymentDetail['payment_name'] === 'Balance'">
              <div class="table table-responsive">
                <tbody>
                  <tr>
                    <td><strong>{{ $t('orderDetail.table.tdCurrentBalance', [metaData.siteName]) }}</strong></td>
                    <td class="pl-5 font-weight-bold text-success">
                      {{ USDCurrencyFormat(currentUser['balance']) }} <small class="text-danger">({{ currencyFormat(currentUser['balance'] * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</small>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>{{ $t('orderDetail.table.tdAfterBalance') }}</strong></td>
                    <td class="pl-5 font-weight-bold text-success">
                      {{ USDCurrencyFormat(currentUser['balance'] - orderObject['total_amount']) }} <small class="text-danger">({{ currencyFormat((currentUser['balance'] - orderObject['total_amount']) * exchangeRateValue(orderObject['created_at']), metaData.defaultCurrency) }})</small>
                    </td>
                  </tr>
                </tbody>
              </div>
            </div>
            <div v-else-if="selectedPaymentDetail['payment_name'] === 'LPOINT'">
              <div class="table table-responsive">
                <tbody>
                  <tr>
                    <td><strong>{{ $t('orderDetail.table.tdCurrentBalance', [metaData.siteName]) }}</strong></td>
                    <td class="pl-5 font-weight-bold text-success">
                      {{ currentUser['lpoint'] }} LPOINT
                    </td>
                  </tr>
                  <tr>
                    <td><strong>{{ $t('orderDetail.table.tdAfterBalance') }}</strong></td>
                    <td class="pl-5 font-weight-bold text-success">
                      {{ currentUser['lpoint'] - orderObject['total_amount'] }} LPOINT
                    </td>
                  </tr>
                </tbody>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="orderObject['status'] !== 'Unpaid'"
      class="mt-2 mb-2"
    >
      <div class="card">
        <div
          v-if="orderObject['status'] === 'Pending'"
          class="card-body"
        >
          <div
            class="text-center text-info"
            v-html="$t('orderDetail.label.notiPending', [metaData.siteName])"
          />
        </div>
        <div
          v-if="orderObject['status'] === 'Partially'"
          class="card-body"
        >
          <div
            class="text-center text-info"
            v-html="$t('orderDetail.label.notiPartially', [metaData.siteName])"
          />
        </div>
        <div
          v-else-if="orderObject['status'] === 'Renewed'"
          class="card-body"
        >
          <div
            class="text-center text-success"
            v-html="$t('orderDetail.label.notiRenewed', [metaData.siteName])"
          />
        </div>
        <div
          v-else-if="orderObject['status'] === 'Provisioned'"
          class="card-body"
        >
          <div
            class="text-center text-success"
            v-html="$t('orderDetail.label.notiProvisioned', [metaData.siteName])"
          />
        </div>
        <div
          v-else-if="orderObject['status'] === 'Credited'"
          class="card-body"
        >
          <div
            class="text-center text-success"
            v-html="$t('orderDetail.label.notiCredited', [metaData.siteName])"
          />
        </div>
        <div
          v-else-if="orderObject['status'] === 'Installing'"
          class="card-body"
        >
          <div
            class="text-center text-primary"
            v-html="$t('orderDetail.label.notiInstalling', [metaData.siteName])"
          />
        </div>
        <div
          v-else-if="orderObject['status'] === 'Cancelled'"
          class="card-body"
        >
          <div
            class="text-center text-secondary"
            v-html="$t('orderDetail.label.notiCancelled', [metaData.siteName])"
          />
        </div>
        <div
          v-else-if="orderObject['status'] === 'Finished'"
          class="card-body"
        >
          <div
            class="text-center text-success"
            v-html="$t('orderDetail.label.notiFinished', [metaData.siteName])"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataRequester from '@/mixins/utilities/DataRequester'
import inputHelper from '@/mixins/utilities/InputHelper'
import levButton from "@/components/children/lev_components/LevButton.vue";
import moment from "moment";
import orderDetail from "../../lang/components/orderDetail";
export default {
  name: 'OrderDetail',
  components: {
    levButton
  },
  mixins: [dataRequester, inputHelper],
  props: {
    currentUser: {},
    metaData: {}
  },
  data: function () {
    return {
      orderObject: {},
      paymentDetailList: [],
      selectedPaymentDetail: '',
      showPaymentOption: '',
      vietQRIsShow: false,
      isButtonLoading: false,
      exchangeRateHistoryList: []
    }
  },
  computed: {
    orderDetail() {
      return orderDetail
    },
    getWebsiteContactDisplay: function () {
      let websiteArr = this.metaData.contactWebsite.split('|')
      return websiteArr.reduce(function (a, s) {
        return a + ' <a href="' + s + '">' + s + '</a> |'
      }, '')
    },
    due: function () {
      let result = {
        amount: this.orderObject['total_amount'],
        textClass: 'text-danger'
      }
      if (this.orderObject['status'] !== 'Unpaid' && this.orderObject['status'] !== 'Pending') {
        result.amount = 0
        result.textClass = 'text-success'
      }
      return result
    },
    isSetupFee: function () {
      let self = this
      return function (serviceOtherInfo) {
        if (![null, undefined].includes(serviceOtherInfo) && self.isValidJSON(serviceOtherInfo)) {
          return JSON.parse(serviceOtherInfo)['description'] === 'Setup fee'
        } else {
          return false
        }
      }
    },
    moneyTransferContent: function () {
      if (this.orderObject['confirm_code'] === null) {
        return `${ this.metaData.siteTicker }${ this.orderObject['id'] }`
      } else {
        return this.orderObject['confirm_code']
      }
    }
  },
  created: function () {
    let self = this
    this.getDataFromAPI(this.$config.apiPath.orderDetail + this.$route.params.orderID, function (orderDetail) {
      self.orderObject = orderDetail.data
      if (self.orderObject['items'] !== undefined) { //
        self.orderObject['items'].sort((a,b) => {
          if ([null, undefined].includes(a['service_other_info']) || [null, undefined].includes(b['service_other_info'])) {
            return 0
          } else {
            return b['service_other_info'].localeCompare(a['service_other_info'])
          }
        })
      }

      self.getDataFromAPI(self.$config.apiPath.paymentDetailList, function (paymentDetail) {
        self.paymentDetailList = paymentDetail.data
        if (orderDetail.data['payment_name'] !== 'Not selected') {
          self.selectedPaymentDetail = self.paymentDetailList.find(p => p['payment_name'] === orderDetail.data['payment_name'])
        }
      })
    })
    this.submitObjectList(this.$config.apiPath.exchangeRateHistory, undefined, undefined, undefined, function(result) {
      if(result.status === 'success'){
        self.exchangeRateHistoryList = result.data['exchangeRateHistoryList']
      } else {
        self.$toasted.error(result.msg)
      }
    })
  },
  methods: {
    selectPaymentDetail: function (paymentName, accountNo) {
      if (paymentName === 'Balance' && this.currentUser['balance'] < this.orderObject['total_amount']) {
        this.selectedPaymentDetail = ''
        this.$toasted.error(this.$t('orderDetail.toast.notEnoughCredit'))
      } else if (paymentName === 'LPOINT' && this.currentUser['lpoint'] < this.orderObject['total_amount']) {
        this.selectedPaymentDetail = ''
        this.$toasted.error(this.$t('orderDetail.toast.notEnoughPoint'))
      } else if (accountNo === undefined) {
        this.selectedPaymentDetail = this.paymentDetailList.find(p => p['payment_name'] === paymentName)
      } else {
        this.selectedPaymentDetail = this.paymentDetailList.find(p => p['payment_name'] === paymentName && p['account_no'] === accountNo)
      }

      this.vietQRIsShow = false
    },
    cancel: function () {
      let self = this
      self.submitObjectList(self.$config.apiPath.orderCancel, {id: self.orderObject['id']}, 'order', undefined, function (result) {
        self.$toasted.success(result.msg)
        self.orderObject['status'] = 'Cancelled'
      })
    },
    nextToPaymentDetail: function () {
      let self = this

      if (this.$route.query['step'] === undefined || this.$route.query['step'] === null || parseInt(this.$route.query['step']) <= 1) {
        this.$router.push('/home/order/detail/' + this.orderObject['id'] + '?step=2')
      } else if (this.$route.query['step'] === '2') {
        if (this.selectedPaymentDetail['id'] === undefined) {
          this.$toasted.error(this.$t('orderDetail.toast.methodNotSelected'))
          return
        }
        if (this.orderObject['total_amount'] === 0) {
          this.$toasted.error(this.$t('orderDetail.toast.emptyCart'))
          return
        }
        if (this.selectedPaymentDetail['payment_name'] === 'Balance' && this.currentUser['balance'] < this.orderObject['total_amount']) {
          // This is necessary because user may insist on using balance when not enough balance
          this.$toasted.error(this.$t('orderDetail.toast.notEnoughCredit'))
          return
        }
        if (this.selectedPaymentDetail['payment_name'] === 'LPOINT' && this.currentUser['lpoint'] < this.orderObject['total_amount']) {
          this.$toasted.error(this.$t('orderDetail.toast.notEnoughPoint'))
          return
        }

        this.orderObject['payment_method_id'] = self.selectedPaymentDetail['id']
        self.isButtonLoading = true

        self.submitObjectList(self.$config.apiPath.orderFinish,
            {id: self.orderObject['id'],
              payment_detail_id: self.selectedPaymentDetail['id']},
            'order', undefined,
            function (result) {
              self.$toasted.success(result.msg)
              self.orderObject['status'] = result.data.status

              // Update current balance to
              self.updateCurrentUserInfo()
            }, undefined, undefined, function () {
              self.isButtonLoading = false
              this.$router.push('/home/order/detail/' + this.orderObject['id'] + '?step=3')
            })
      }
    },
    backToPreviousStep: function () {
      if (this.$route.query['step'] === undefined || this.$route.query['step'] === null || parseInt(this.$route.query['step']) <= 1) {
        this.$router.push('/home/order/history')
      } else {
        this.$router.push('/home/order/detail/' + this.orderObject['id'] + '?step=1')
      }
    },
    exchangeRateValue (orderCreatedAt) {
      let exchangeRateValue
      if (orderCreatedAt === undefined) {
        exchangeRateValue = null
      } else {
        let exchangeRate = this.exchangeRateHistoryList.find(e => moment(e['applied_at']).isSameOrBefore(orderCreatedAt))
        if (exchangeRate === undefined) {
          exchangeRateValue = null
        } else {
          exchangeRateValue = exchangeRate['exchange_rate']
        }
      }
      return exchangeRateValue
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.orderObject['status'] === 'Unpaid') {
      const answer = window.confirm(this.$t('orderDetail.alert.unpaid'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>

<style scoped>

</style>
